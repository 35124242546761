import classNames from 'classnames';
import React from 'react';
import { DashboardTabs, tabs } from 'src/modules/dashboard';
import { ReactComponent as DollarIcon } from 'src/components/DashboardWrapper/assets/dollar.svg';
import { ReactComponent as ExitIcon } from 'src/components/DashboardWrapper/assets/exit.svg';
import { ReactComponent as HomeIcon } from 'src/components/DashboardWrapper/assets/home.svg';
import { ReactComponent as SettingsIcon } from 'src/components/DashboardWrapper/assets/settings.svg';
import { ReactComponent as HelpIcon } from 'src/components/DashboardWrapper/assets/information.svg';
import GC from 'src/assets/android-chrome-512x512.png';
import styles from './Sidebar.module.scss';
import { useHistory } from 'react-router-dom';
import hrefs from 'src/modules/href';
import { useApi } from 'src/api';

type TabProps = {
  title: DashboardTabs;
  children: JSX.Element;
  selectedTab: DashboardTabs;
  onClick: () => void;
};

function Tab({ title, children, selectedTab, onClick }: TabProps): JSX.Element {
  return (
    <div
      className={classNames(
        styles.Tab,
        selectedTab === title && styles.SelectedTab,
      )}
      onClick={onClick}
    >
      {children}
      <span className={styles.TabTitle}>{title}</span>
    </div>
  );
}

type SidebarProps = {
  selectedTab: DashboardTabs;
  setShowHelpModal: (a: boolean) => void;
};

export default function Sidebar({
  selectedTab,
  setShowHelpModal,
}: SidebarProps): JSX.Element {
  const history = useHistory();
  const api = useApi();
  //this is where to change tracking things

  const thisIsMyCopy = `<!-- Meta Pixel Code -->
  <script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '356670329269065');
  fbq('track', 'Subscribe');
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=356670329269065&ev=PageView&noscript=1"
  /></noscript>
  <!-- End Meta Pixel Code -->
  
  
  <!-- Event snippet for Subscribe conversion page -->
  <script>
    gtag('event', 'conversion', {'send_to': 'AW-10847887569/c4xACOaMrbIDENHB1rQo'});
  </script>`;
  return (
    <div className={styles.Sidebar}>
      <div className={styles.Info}>
        <div className={styles.Top}>
          <div className={styles.HeaderTitle}>
            <img src={GC} alt='icon' className={styles.Logo} />
          </div>

          <div className={styles.MainSection}>
            <Tab
              title={tabs.home}
              selectedTab={selectedTab}
              onClick={() => history.push(hrefs.dashboard())}
            >
              <HomeIcon
                className={classNames(
                  styles.TabIcon,
                  tabs.home === selectedTab && styles.SelectedIcon,
                )}
              />
            </Tab>
            <Tab
              title={tabs.settings}
              selectedTab={selectedTab}
              onClick={() => history.push(hrefs.settings())}
            >
              <SettingsIcon
                className={classNames(
                  styles.TabIcon,
                  tabs.settings === selectedTab && styles.SelectedIcon,
                )}
              />
            </Tab>
            <Tab
              title={tabs.affiliate}
              selectedTab={selectedTab}
              onClick={() =>
                window.open('https://gocopy.getrewardful.com/signup', '_blank')
              }
            >
              <DollarIcon
                className={classNames(
                  styles.TabIcon,
                  tabs.affiliate === selectedTab && styles.SelectedIcon,
                )}
              />
            </Tab>
          </div>
        </div>
        <div className={styles.Bottom}>
          <div className={styles.Tab} onClick={() => setShowHelpModal(true)}>
            <HelpIcon className={styles.TabIcon} />
            <span className={styles.TabTitle}>Help & Support</span>
          </div>
          <div className={styles.Tab} onClick={() => api.logout()}>
            <ExitIcon className={styles.TabIcon} />
            <span className={styles.TabTitle}>Sign out</span>
          </div>
          <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>

        </div>
      </div>
    </div>
  );
}
