import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import generator from 'generate-password';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import styles from './SignUpPage.module.scss';
import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import FormInput from 'src/components/FormInput';
import { ReactComponent as GoogleIcon } from 'src/assets/google.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/facebook.svg';
import { useApi } from 'src/api';
import href from 'src/modules/href';
import Spinner from 'src/components/Spinner';
import { validateEmail, validatePassword } from 'src/utils/validation';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/redux/selectors/auth';
import { UserForm } from 'src/modules/user';

export default function SignUpPageSplit(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const api = useApi();
  const userId = useSelector(getUserId);
  const history = useHistory();
  const emailError = validateEmail(email);
  const passwordError = validatePassword(password);
  const coupon: string | undefined = undefined;
  const checkEmail = (userForm: UserForm) => {
    const encryptedUser = btoa(JSON.stringify(userForm));
    api
      .emailConfirmation({
        email: userForm.email,
        link: encryptedUser,
      })
      .then(() => {
        history.push(
          href.emailConfirmationSplit({
            encodedUser: encryptedUser,
          }),
        );
      })
      .catch(() => {
        setApiError('A user with this email exists already.');
        setIsLoading(false);
      });
  };
  if (userId) {
    history.push(href.dashboard());
    return <div className={styles.LandingPage} />;
  }
  return (
    <AuthenticationWrapper>
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>GoCopy - Authentication</title>
          <link rel='canonical' href={`https://gocopy.io${href.signUpSplit()}`} />
        </Helmet>
        <div className={styles.SignUpPage}>
          <div className={styles.Title}>
            <span>Let's Go!</span>
          </div>
          <div className={styles.Subtitle}>
          <span>Writing simpified for free.</span>
          {/* <span>Writing simpified for free. No credit card required.</span> */}
          </div>
          {apiError && <div className={styles.ApiError}>{apiError}</div>}
          <div className={styles.FormInputContainer}>
            <FormInput
              title='E-mail'
              placeholderText='Enter your e-mail address'
              password={false}
              input={email}
              onChange={e => setEmail(e.target.value)}
              className={styles.FormInput}
              error={showErrors ? emailError : ''}
            />
          </div>
          <FormInput
            title='Password'
            placeholderText='Enter your password'
            password
            input={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.FormInput}
            passwordMessage='Minimum of 8 characters'
            error={showErrors ? passwordError : ''}
          />
          <Tippy content='Woohoo!'>
            <button
              className={styles.CreateAccountButton}
              onClick={() => {
                setShowErrors(true);
                if (!emailError && !passwordError) {
                  setIsLoading(true);
                  const userForm = {
                    email,
                    password: generator.generate({
                      length: 10,
                      numbers: true,
                    }),
                    coupon,
                    ipAddress: Math.random().toString(),
                  };
                  checkEmail(userForm);
                }
              }}
            >
              {isLoading ? <Spinner size={12} color='#ffffff' /> : 'Get GoCopy'}
            </button>
          </Tippy>
          <div className={styles.TandC}>
            <span>By clicking “Create Account” you agree to GoCopy’s </span>
            <span
              className={styles.TermsText}
              onClick={() => history.push(href.termsOfService())}
            >
              Terms of Service
            </span>
            <span> and </span>
            <span
              className={styles.TermsText}
              onClick={() => history.push(href.privacyPolicy())}
            >
              Privacy Policy
            </span>
          </div>
          <div className={styles.Split}>
            <hr className={styles.HR} />
            <div className={styles.Or}>or</div>
            <hr className={styles.HR} />
          </div>
          <div className={styles.SocialAuth}>
            <GoogleLogin
              clientId={
                '753781081483-n2nbh24nodqke8fkc02osfdbg0s1jghp.apps.googleusercontent.com'
              }
              render={renderProps => (
                <Tippy content='Woohoo!'>
                  <div
                    className={styles.AuthContainer}
                    onClick={renderProps.onClick}
                  >
                    <GoogleIcon className={styles.SocialIcon} />
                  </div>
                </Tippy>
              )}
              onSuccess={res => {
                const { profileObj } = res as GoogleLoginResponse;
                const { email } = profileObj;
                const userForm = {
                  email,
                  password: generator.generate({
                    length: 10,
                    numbers: true,
                  }),
                  coupon,
                  ipAddress: Math.random().toString(),
                };
                checkEmail(userForm);
              }}
            />
            <FacebookLogin
              appId='454446472354832'
              autoLoad={false}
              fields='email'
              onClick={() => {}}
              callback={res => {
                const { email } = res;
                if (email) {
                  const userForm = {
                    email,
                    password: generator.generate({
                      length: 10,
                      numbers: true,
                    }),
                    coupon,
                    ipAddress: Math.random().toString(),
                  };
                  checkEmail(userForm);
                }
              }}
              render={renderProps => (
                <Tippy content='Oh yeah!'>
                  <div
                    className={styles.AuthContainer}
                    onClick={renderProps.onClick}
                  >
                    <FacebookIcon className={styles.SocialIcon} />
                  </div>
                </Tippy>
              )}
            />
          </div>
        </div>
        <div className={styles.LogIn}>
          <span>Already have an account?</span>
          <span
            className={styles.LogInText}
            onClick={() => history.push(href.signIn())}
          >
            Log in
          </span>
        </div>
      </>
    </AuthenticationWrapper>
  );
}
