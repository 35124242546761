import React from 'react';
import { Helmet } from 'react-helmet';
import href from 'src/modules/href';

type HelmetProps = {
  title: string;
  description?: string;
  link: string;
};

function HelmetContainer({
  title,
  link,
  description,
}: HelmetProps): JSX.Element {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
      <link rel='canonical' href={link} />
    </Helmet>
  );
}

type Props = {
  url: string;
};

export default function PageHelmet({ url }: Props): JSX.Element {
  const link = `https://gocopy.io${url}`;
  const props = { link };
  switch (url) {
    case href.billing():
      return <HelmetContainer title='GoCopy - Billing' {...props} />;
    case href.settings():
      return <HelmetContainer title='GoCopy - Account Settings' {...props} />;
    case href.dashboard():
      return <HelmetContainer title='GoCopy - Home' {...props} />;
    case href.playground({ projectId: ':projectId', taskId: ':taskId' }):
      return <HelmetContainer title='GoCopy - Playground' {...props} />;
    default:
      return (
        <HelmetContainer
          title='GoCopy: Free Writing Assistant'
          {...props}
          description="Thousands trust GoCopy's free AI writing assistant to double their speed at writing blogs, digital ads, product descriptions, and much more. Getting started is simple — sign up today."
        />
      );
  }
}
