import {
  ConvertResponse,
  CreateCheckoutSession,
  CustomerPortalUrl,
  PlagiarismResponse,
} from './responseTypes';
import { GetUserByResetIdResponse } from 'src/redux/actions/user';
import { pickValueForDeploymentEnv } from 'src/utils/env';
import { Authentication } from '../authentication';
import { DisappointmentTypes } from '../feedback';
import { Project } from '../Project';
import { BlogSection, Result, Task, UpdateWriteForMeResult } from '../task';
import { TaskPromptProps } from '../task/promptProps';
import { User } from '../user';
import { Http, Response } from './http';
import { Generation } from '../statistics';
import { TaskTypes } from '../task/tasktypes';
import { Ltd } from '../ltd';
import { Languages } from '../languages/languages';
import { ActivationCode } from '../activationCode';
import { OutputLengthTypes } from '../playground';
import { EditorSettings } from '../editorSetttings';
import { Discount } from '../discount';
import { Favourite } from '../favourites';
import { SharedFolder } from '../shared';
import { SubscriptionData } from '../subscriptionData';


/**
 * API client interface
 */

export interface Api {
  signUp(signUpForm: {
    email: string;
    password: string;
    code?: string;
    coupon?: string;
    ipAddress: string;
  }): Promise<Authentication>;

  signUpAppsumo(signUpForm: {
    email: string;
    password: string;
    code?: string;
  }): Promise<Authentication>;

  signIn(signInForm: {
    email: string;
    password: string;
  }): Promise<Authentication>;

  thirdPartySignIn(email: string): Promise<Authentication>;

  logout(): Promise<Authentication>;

  getUser(userId: string): Promise<User>;

  getUsers(): Promise<User[]>;

  getUserByEmail(email: string): Promise<User>;

  updateUser(user: User): Promise<User>;

  deleteUser(userId: string): Promise<Authentication>;

  refreshToken(userId: string): Promise<string>;

  resetPassword(email: string): Promise<Authentication>;

  updateResetPassword(resetId: string): Promise<string>;

  getUserByResetId(resetId: string): Promise<GetUserByResetIdResponse>;

  updatePassword(form: { userId: string; password: string }): Promise<boolean>;

  emailConfirmation(confirmationForm: {
    email: string;
    link: string;
  }): Promise<boolean>;

  getProject(projectId: string): Promise<Project>;

  getProjects(userId: string): Promise<Project[]>;

  createProject(form: {
    projectName: string;
    userId: string;
  }): Promise<Project>;

  createTask(form: {
    projectId: string;
    userId: string;
    taskType: string;
    taskName?: string;
  }): Promise<Task>;

  deleteTask(form: { projectId: string; taskId: string }): Promise<object>;

  getTasks(projectId: string): Promise<Task[]>;

  getTasksByUserId(userId: string): Promise<Task[]>;

  getTask(form: { projectId: string; taskId: string }): Promise<Task>;

  updateTaskName(form: {
    projectId: string;
    taskId: string;
    newName: string;
  }): Promise<Task>;

  likeResult(form: {
    projectId: string;
    taskId: string;
    result: Result;
  }): Promise<Task>;

  unlikeResult(form: {
    projectId: string;
    taskId: string;
    id: string;
  }): Promise<Task>;

  generateIdeas(form: {
    projectId: string;
    taskId: string;
    promptProps: TaskPromptProps;
    n?: number;
    userId: string;
    language?: Languages;
  }): Promise<Result[]>;

  generateInlineIdeas(form: {
    promptProps: TaskPromptProps;
    n?: number;
    userId: string;
    projectId: string;
    taskId: string;
    language?: Languages;
  }): Promise<Task>;

  generateWriteForMe(form: {
    promptProps: TaskPromptProps;
    n?: number;
    userId: string;
    projectId: string;
    taskId: string;
    maxTokens?: number;
    language?: Languages;
  }): Promise<Task>;

  generateBlogPost(form: {
    promptProps: TaskPromptProps;
    userId: string;
  }): Promise<BlogSection[]>;

  updateTaskInputs(form: {
    projectId: string;
    taskId: string;
    currentInputs: object;
    taskType: TaskTypes;
  }): Promise<Task>;

  createFeedback(form: {
    userId: string;
    disappointmentLevel: DisappointmentTypes;
    description: string;
  }): Promise<string>;

  updateProjectName(form: {
    projectId: string;
    newProjectName: string;
  }): Promise<Project>;

  createCheckoutSession(form: {
    priceId: string;
    customerId: string;
    mode?: string;
    clientReferenceId?: string;
    discount?: Discount | undefined;
    subscriptionData?: SubscriptionData | undefined;
  }): Promise<CreateCheckoutSession>;

  getCheckoutSession(sessionId: string): Promise<object>;

  createCustomerPortal(customerId: string): Promise<CustomerPortalUrl>;

  getStatistics(userId: string): Promise<Generation>;

  updateStatistics(
    userId: string,
    type: TaskTypes,
    generatedWordsCount?: number,
  ): Promise<Generation>;

  savePlaygroundText(form: {
    taskId: string;
    projectId: string;
    playgroundText: string;
    taskName: string;
  }): Promise<Task>;

  saveEditorContent(form: {
    taskId: string;
    projectId: string;
    playgroundText: string;
  }): Promise<string>;

  convertToDocx(html: string): Promise<ConvertResponse>;

  makePlagiarismCheck(data: string): Promise<PlagiarismResponse>;

  updatePlagiarismStatistics(userId: string): Promise<string>;

  deleteProject(form: { projectId: string; userId: string }): Promise<string>;

  getLtdStats(): Promise<Ltd>;

  updateTranslationLanguage(form: {
    projectId: string;
    taskId: string;
    language: Languages;
  }): Promise<Languages>;

  getActivationCode(email: string): Promise<ActivationCode>;

  expireActivationCode(id: string): Promise<string>;

  updateWriteForMe(form: {
    projectId: string;
    taskId: string;
    contentBrief: string;
    outputLength: OutputLengthTypes;
    title: string;
  }): Promise<UpdateWriteForMeResult>;

  updateEditorSettings(form: EditorSettings): Promise<EditorSettings>;

  getEditorSettings(userId: string): Promise<EditorSettings>;

  getFavourites(userId: string): Promise<Favourite[]>;

  createFavourite(favourite: Favourite): Promise<string>;

  removeFavourite(favourite: Favourite): Promise<string>;

  createSharedFolder(form: {
    adminId: string;
    folderId: string;
    newMemberId: string;
  }): Promise<SharedFolder>;

  addMemberToSharedFolder(form: {
    adminId: string;
    folderId: string;
    newMemberId: string;
  }): Promise<string>;

  removeMemberFromSharedFolder(form: {
    adminId: string;
    folderId: string;
    memberId: string;
  }): Promise<string>;

  getSharedFolders(userId: string): Promise<SharedFolder[]>;

  getSharedFoldersAdmin(userId: string): Promise<SharedFolder[]>;

  getSharedProject(projectId: string): Promise<Project>;

  stackCode(form: { userId: string; code: string }): Promise<User>;
}

type QueryParams = { [param: string]: string | number | (string | number)[] };

const baseUrl = pickValueForDeploymentEnv(
  '/', // Use a relative url during dev/test or as a fallback
  'https://api.gocopy.io/',
);

/**
 * Build a url using the given path and optional query parameters.
 * @param path Path to append to the base url.
 * @param params Optional query parameters to encode.
 * @returns Full url with the base prefix and any query parameters encoded.
 */
export function url(path: string, params: QueryParams = {}): string {
  const queryString = Object.entries(params)
    .flatMap(([k, v]) => {
      return Array.isArray(v)
        ? v.map(vi => `${encodeURIComponent(k)}=${encodeURIComponent(vi)}`)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    })
    .join('&');

  return baseUrl + path + (queryString && `?${queryString}`);
}

/**
 * Create an API client.
 * @param http HTTP client.
 * @returns API client.
 */
export const createApi = (http: Http): Api => ({
  async signUp(signUpForm): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url('auth/signup'),
      signUpForm,
    );
    return res.data;
  },
  async signUpAppsumo(signUpForm): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url('auth/appsumo/signup'),
      signUpForm,
    );
    return res.data;
  },
  async signIn(signInForm): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url('auth/login'),
      signInForm,
    );
    return res.data;
  },
  async thirdPartySignIn(email): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url('auth/third-party-login'),
      { email },
    );
    return res.data;
  },
  async getUser(userId): Promise<User> {
    const res: Response<User> = await http.get(url(`user/${userId}`));
    return res.data;
  },
  async getUsers(): Promise<User[]> {
    const res: Response<User[]> = await http.get(url('user/users/all'));
    return res.data;
  },
  async getUserByEmail(email): Promise<User> {
    const res: Response<User> = await http.get(url(`user/byEmail/${email}`));
    return res.data;
  },
  async updateUser(user): Promise<User> {
    const res: Response<User> = await http.post(url(`user/update`), user);
    return res.data;
  },
  async refreshToken(userId): Promise<string> {
    const res: Response<string> = await http.post(url('auth/refresh-token'), {
      userId,
    });
    return res.data;
  },
  async resetPassword(email): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url('auth/resetPassword'),
      { email },
    );
    return res.data;
  },
  async updateResetPassword(resetId): Promise<string> {
    const res: Response<string> = await http.post(
      url('auth/resetPassword/update'),
      { resetId },
    );
    return res.data;
  },
  async getUserByResetId(resetId: string): Promise<GetUserByResetIdResponse> {
    const res: Response<GetUserByResetIdResponse> = await http.get(
      url(`user/getUserByResetId/${resetId}`),
    );
    return res.data;
  },
  async updatePassword(form): Promise<boolean> {
    const res: Response<boolean> = await http.post(
      url(`user/updatePassword`),
      form,
    );
    return res.data;
  },
  async logout(): Promise<Authentication> {
    const res: Response<Authentication> = await http.post(
      url(`auth/logout`),
      {},
    );
    return res.data;
  },
  async deleteUser(userId): Promise<Authentication> {
    const res: Response<Authentication> = await http.delete(
      url(`user/delete/${userId}`),
    );
    return res.data;
  },
  async emailConfirmation(confirmationForm): Promise<boolean> {
    const res: Response<boolean> = await http.post(
      url('auth/emailConfirmation'),
      confirmationForm,
    );
    return res.data;
  },
  async getProject(projectId): Promise<Project> {
    const res: Response<Project> = await http.get(url(`project/${projectId}`));
    return res.data;
  },
  async getProjects(userId): Promise<Project[]> {
    const res: Response<Project[]> = await http.get(
      url(`project/all/${userId}`),
    );
    return res.data;
  },
  async createProject(form): Promise<Project> {
    const res: Response<Project> = await http.post(url(`project/create`), form);
    return res.data;
  },
  async createTask(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('task/create'), form);
    return res.data;
  },
  async deleteTask(form): Promise<object> {
    const res: Response<object> = await http.delete(
      url(`task/${form.projectId}/${form.taskId}`),
    );
    return res.data;
  },
  async getTask(form): Promise<Task> {
    const { taskId, projectId } = form;
    const res: Response<Task> = await http.get(
      url(`task/${projectId}/${taskId}`),
    );
    return res.data;
  },
  async getTasks(projectId): Promise<Task[]> {
    const res: Response<Task[]> = await http.get(url(`task/all/${projectId}`));
    return res.data;
  },
  async getTasksByUserId(userId): Promise<Task[]> {
    const res: Response<Task[]> = await http.get(
      url(`task/all-by-user-id/${userId}`),
    );
    return res.data;
  },
  async updateTaskName(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('task/updateName'), form);
    return res.data;
  },
  async likeResult(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('task/like'), form);
    return res.data;
  },
  async unlikeResult(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('task/unlike'), form);
    return res.data;
  },
  async generateIdeas(form): Promise<Result[]> {
    const res: Response<Result[]> = await http.post(url('generate/'), form);
    return res.data;
  },
  async generateInlineIdeas(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('generate/inline'), form);
    return res.data;
  },
  async generateWriteForMe(form): Promise<Task> {
    const res: Response<Task> = await http.post(
      url('generate/write-for-me'),
      form,
    );
    return res.data;
  },
  async generateBlogPost(form): Promise<BlogSection[]> {
    const res: Response<BlogSection[]> = await http.post(
      url('generate/blog-post'),
      form,
    );
    return res.data;
  },
  async updateTaskInputs(form): Promise<Task> {
    const res: Response<Task> = await http.post(url('task/updateInputs'), form);
    return res.data;
  },
  async createFeedback(form): Promise<string> {
    const res: Response<string> = await http.post(url('feedback/'), form);
    return res.data;
  },
  async updateProjectName(form): Promise<Project> {
    const res: Response<Project> = await http.post(
      url('project/updateProjectName'),
      form,
    );
    return res.data;
  },
  async createCheckoutSession(form): Promise<CreateCheckoutSession> {
    const res: Response<CreateCheckoutSession> = await http.post(
      url('payment/create-checkout-session'),
      form,
    );
    return res.data;
  },
  async getCheckoutSession(sessionId): Promise<object> {
    const res: Response<object> = await http.get(
      url(`payment/checkout-session/${sessionId}`),
    );
    return res.data;
  },
  async createCustomerPortal(customerId): Promise<CustomerPortalUrl> {
    const res: Response<CustomerPortalUrl> = await http.post(
      url('payment/customer-portal'),
      { customerId },
    );
    return res.data;
  },
  async getStatistics(userId): Promise<Generation> {
    const res: Response<Generation> = await http.get(
      url(`statistics/${userId}`),
    );
    return res.data;
  },
  async updateStatistics(
    userId,
    type,
    generatedWordsCount,
  ): Promise<Generation> {
    const res: Response<Generation> = await http.post(url(`statistics/`), {
      userId,
      type,
      generatedWordsCount,
    });
    return res.data;
  },
  async savePlaygroundText(form): Promise<Task> {
    const res: Response<Task> = await http.post(
      url('task/save-playground-text'),
      form,
    );
    return res.data;
  },
  async saveEditorContent(form): Promise<string> {
    const res: Response<string> = await http.post(
      url('task/save-editor-content'),
      form,
    );
    return res.data;
  },
  async convertToDocx(html): Promise<ConvertResponse> {
    const res: Response<ConvertResponse> = await http.post(
      url('task/convert-to-docx'),
      {
        html,
      },
    );
    return res.data;
  },
  async makePlagiarismCheck(data): Promise<PlagiarismResponse> {
    const res: Response<PlagiarismResponse> = await http.post(
      'https://www.prepostseo.com/apis/checkPlag',
      { data, key: '4c9885445bf4b5f97ac6810ecbc49967' },
    );
    return res.data;
  },
  async updatePlagiarismStatistics(userId): Promise<string> {
    const res: Response<string> = await http.post(
      url('statistics/plagiarism'),
      { userId },
    );
    return res.data;
  },
  async deleteProject(form): Promise<string> {
    const res: Response<string> = await http.delete(
      url(`project/${form.userId}/${form.projectId}`),
    );
    return res.data;
  },
  async getLtdStats(): Promise<Ltd> {
    const res: Response<Ltd> = await http.get(url('ltd/'));
    return res.data;
  },
  async updateTranslationLanguage(form): Promise<Languages> {
    const res: Response<Languages> = await http.post(
      url('task/update-translation-language'),
      form,
    );
    return res.data;
  },
  async expireActivationCode(id): Promise<string> {
    const res: Response<string> = await http.post(
      url('auth/activationCode/expire'),
      { id },
    );
    return res.data;
  },
  async getActivationCode(email): Promise<ActivationCode> {
    const res: Response<ActivationCode> = await http.get(
      url(`auth/activationCode/${email}`),
    );
    return res.data;
  },
  async updateWriteForMe(form): Promise<UpdateWriteForMeResult> {
    const res: Response<UpdateWriteForMeResult> = await http.post(
      url('task/update-write-for-me'),
      form,
    );
    return res.data;
  },
  async updateEditorSettings(form): Promise<EditorSettings> {
    const res: Response<EditorSettings> = await http.post(
      url('editor-settings/update'),
      form,
    );
    return res.data;
  },
  async getEditorSettings(userId): Promise<EditorSettings> {
    const res: Response<EditorSettings> = await http.get(
      url(`editor-settings/${userId}`),
    );
    return res.data;
  },
  async getFavourites(userId): Promise<Favourite[]> {
    const res: Response<Favourite[]> = await http.get(
      url(`favourites/${userId}`),
    );
    return res.data;
  },
  async createFavourite(favourite): Promise<string> {
    const res: Response<string> = await http.post(
      url('favourites/add'),
      favourite,
    );
    return res.data;
  },
  async removeFavourite(favourite): Promise<string> {
    const res: Response<string> = await http.post(
      url('favourites/remove'),
      favourite,
    );
    return res.data;
  },
  async createSharedFolder(form): Promise<SharedFolder> {
    const res: Response<SharedFolder> = await http.post(
      url('shared-folders/create'),
      form,
    );
    return res.data;
  },
  async addMemberToSharedFolder(form): Promise<string> {
    const res: Response<string> = await http.post(
      url('shared-folders/add-member'),
      form,
    );
    return res.data;
  },
  async removeMemberFromSharedFolder(form): Promise<string> {
    const res: Response<string> = await http.post(
      url('shared-folders/remove-member'),
      form,
    );
    return res.data;
  },
  async getSharedFolders(userId): Promise<SharedFolder[]> {
    const res: Response<SharedFolder[]> = await http.get(
      url(`shared-folders/${userId}`),
    );
    return res.data;
  },
  async getSharedFoldersAdmin(userId): Promise<SharedFolder[]> {
    const res: Response<SharedFolder[]> = await http.get(
      url(`shared-folders/owner/${userId}`),
    );
    return res.data;
  },
  async getSharedProject(projectId): Promise<Project> {
    const res: Response<Project> = await http.get(
      url(`shared-folders/projects/${projectId}`),
    );
    return res.data;
  },
  async stackCode(form): Promise<User> {
    const res: Response<User> = await http.post(url('user/stack-code'), form);
    return res.data;
  },
});
