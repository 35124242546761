import React from 'react';
import { useHistory } from 'react-router-dom';
import href from 'src/modules/href';
import { executeScroll } from 'src/utils/scroll';
import styles from './Footer.module.scss';

type FooterProps = {
  productRef: React.MutableRefObject<HTMLDivElement>;
  testimonialRef: React.MutableRefObject<HTMLDivElement>;
  pricingRef: React.MutableRefObject<HTMLDivElement>;
};

export default function Footer({
  productRef,
  testimonialRef,
  pricingRef,
}: FooterProps): JSX.Element {
  const history = useHistory();
  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
        <div className={styles.Column}>
          <div className={styles.Title}>Explore</div>
          <div className={styles.Items}>
            <div
              className={styles.Item}
              onClick={() => executeScroll(productRef)}
            >
              Product
            </div>
            <div
              className={styles.Item}
              onClick={() => executeScroll(testimonialRef)}
            >
              Testimonials
            </div>
            <div
              className={styles.Item}
              onClick={() => executeScroll(pricingRef)}
            >
              Pricing
            </div>
          </div>
        </div>
        <div className={styles.Column}>
          <div className={styles.Title}>Terms</div>
          <div className={styles.Items}>
            <div
              className={styles.Item}
              onClick={() => history.push(href.termsOfService())}
            >
              Terms of Use
            </div>
            <div
              className={styles.Item}
              onClick={() => history.push(href.privacyPolicy())}
            >
              Privacy Policy
            </div>
          </div>
        </div>
        <div className={styles.Column}>
          <div className={styles.Title}>Get in Touch</div>
          <div className={styles.Items}>
            <div
              className={styles.Item}
              onClick={() =>
                (window.location.href = 'mailto:support@gocopy.io')
              }
            >
              support@gocopy.io
            </div>
            <div className={styles.Item}>© 2021 All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  );
}
