import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useApi } from 'src/api';
import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import FormInput from 'src/components/FormInput';
import href from 'src/modules/href';
import styles from './ResetPassword.module.scss';

export default function ResetPasswordPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const api = useApi();
  return (
    <AuthenticationWrapper>
      <div className={styles.ResetPasswordPage}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>GoCopy - Great See You Again. </title>
          <link
            rel='canonical'
            href={`https://gocopy.io${href.resetPassword()}`}
          />
        </Helmet>
        {emailSubmitted ? (
          <>
            <div className={styles.Title}>Welcome back!</div>
            <div className={styles.RecoveryLink}>Recovery link sent</div>
            <div className={styles.Info}>
              Please check your inbox. The link should arrive in the next 1-3
              minutes.
            </div>
          </>
        ) : (
          <div>
            <div className={styles.Title}>Reset Password</div>
            <FormInput
              title='E-mail'
              placeholderText='Enter your e-mail address'
              className={styles.FormInput}
              input={email}
              onChange={e => setEmail(e.target.value)}
              password={false}
            />
            <button
              className={styles.SendLink}
              onClick={() =>
                api.resetPassword(email).then(() => setEmailSubmitted(true))
              }
            >
              Send me the link
            </button>
          </div>
        )}
      </div>
    </AuthenticationWrapper>
  );
}
