import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cursor } from 'src/assets/cursor.svg';
import { ReactComponent as Robot } from 'src/assets/robot.svg';
import { ReactComponent as Ideas } from 'src/assets/ideas.svg';
import { ReactComponent as Writing } from 'src/assets/writing.svg';
import { ReactComponent as Blog } from 'src/assets/blog.svg';
import { ReactComponent as DigitalAds } from 'src/assets/digitalads.svg';
import { ReactComponent as Ecommerce } from 'src/assets/ecommerce.svg';
import { ReactComponent as CheckIcon } from 'src/assets/tick.svg';
import { ReactComponent as Arrow } from 'src/assets/general-arrow.svg';
import { ReactComponent as Editor } from 'src/assets/editor.svg';
import AimeeImg from 'src/assets/aimeebradshaw.jpeg';
import GrahamImg from 'src/assets/grahamensor.jpeg';
import HannahImg from 'src/assets/hannahcesarani.jpeg';
import VitImg from 'src/assets/vit.jpeg';
import SevdaImg from 'src/assets/sevda.jpeg';
import AnthonyImg from 'src/assets/anthony.jpeg';
import href from 'src/modules/href';
import styles from './Landing.module.scss';
import Typist from 'react-typist';
import Tippy from '@tippyjs/react';
import Footer from 'src/components/Footer';
import Pricing from 'src/components/NewPricing';
import { useWindowPosition } from 'src/utils/windowPosition';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/redux/selectors/auth';
import PageHelmet from 'src/components/PageHelmet';
import Logo from 'src/components/Logo';
import TestimonialCard from 'src/components/TestimonialCard';

const COUNTDOWN = 4;

type Usecase = {
  buttonText: string;
  inProgressText: string;
  original: string;
  updated: string;
};

export default function NewLandingPage(): JSX.Element {
  const history = useHistory();
  const usecases: Usecase[] = [
    {
      buttonText: 'Rewrite',
      inProgressText: 'Rewriting...',
      original:
        'Hey, I hope you are well! I would really appreciate it if you could take a look at my essay before I hand it in on Thursday. ',
      updated:
        'Hi! I hope you are well. I need your help with something. Could you look over my essay and give me your feedback before I hand it in on Thursday?',
    },
    {
      buttonText: 'Condense',
      inProgressText: 'Condensing...',
      original:
        'In December of 1999, Lord Hohlwein went skiing in the Swiss mountains. The sun was shining and skied the entire day. It’s a day he remembers fondly.',
      updated:
        ' In 1999, Lord Hohlwein skied in the Swiss mountains. The sun was shining and he skied all day. It’s a day he remembers fondly.',
    },
    {
      buttonText: 'Expand',
      inProgressText: 'Expanding...',
      original: 'SEO is important for growing your online business',
      updated:
        ' Search Engine Optimization is the process of ranking highly on Google for certain keywords. SEO can be crucial to growing your business online. If you have a website, you can optimize that website to rank high on Google. This can help your business to grow very quickly, since people will be able to find your website using their Google search engine.',
    },
  ];
  const testimonialRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const productRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const pricingRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [currentUsecaseIdx, setCurrentUsecaseIdx] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const currentUsecase = usecases[currentUsecaseIdx];
  const [timeLeft, setTimeLeft] = useState(COUNTDOWN);
  const windowPosition = useWindowPosition();
  const userId = useSelector(getUserId);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft - 1 === 1) {
        setInProgress(true);
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  if (userId) {
    history.push(href.dashboard());
    return <div className={styles.LandingPage} />;
  }
  return (
    <div className={styles.LandingPage}>
      <PageHelmet url={href.landing()} />
      <div
        className={classNames(
          styles.Navbar,
          windowPosition > 100 && styles.ActiveNavbar,
        )}
      >
        <Logo />
        <div className={styles.NavbarButtons}>
          <button
            className={styles.LogIn}
            onClick={() => history.push(href.signIn())}
          >
            Log In
          </button>
          {windowPosition > 100 && (
            <div
              className={classNames(styles.SignUp, styles.NavbarSize)}
              onClick={() => window.open(href.externalQuiz(), '_blank')}
            >
              <span className={classNames(styles.ButtonMain)}>
                Simplify writing now
              </span>
              <span className={styles.ButtonSecondary}>It's free</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.First}>
        <div className={styles.FirstContainer}>
          <div className={styles.FirstSubContainer}>
            <div className={styles.HeroContainer}>
              <h1 className={styles.Header}>Make writing easier</h1>
              <p className={styles.SubHeader}>
                Team up with our AI-powered writing assistant to help you
                ideate, copywrite, get your thoughts on paper, and much more.
              </p>
              <div className={styles.ButtonContainer}>
                <div
                  className={styles.SignUp}
                  onClick={() => window.open(href.externalQuiz(), '_blank')}
                >
                  <span className={styles.ButtonMain}>
                    Simplify writing now
                  </span>
                  <span className={styles.ButtonSecondary}>It's free</span>
                </div>
                {/* <span className={styles.NoCC}>No credit card required</span> */}
              </div>
            </div>
            <div className={styles.UseCases}>
              <div className={styles.UseCaseHeader}>
                <Cursor
                  className={classNames(
                    styles.CursorIcon,
                    timeLeft === 2 && styles.Animation,
                    inProgress && styles.EndCursorPosition,
                  )}
                />
                <div
                  className={classNames(
                    styles.UseCaseButton,
                    timeLeft > 2 && styles.FadeInText,
                  )}
                >
                  {inProgress
                    ? currentUsecase.inProgressText
                    : currentUsecase.buttonText}
                </div>
              </div>
              <hr className={styles.Hr} />
              <div
                className={classNames(
                  styles.Text,
                  timeLeft > 2 && styles.FadeInText,
                )}
              >
                {timeLeft === 0 ? (
                  <Typist
                    avgTypingDelay={20}
                    cursor={{ hideWhenDone: true, hideWhenDoneDelay: 0 }}
                    onTypingDone={() => {
                      setTimeout(() => {
                        setTimeLeft(COUNTDOWN);
                        setInProgress(false);
                        if (currentUsecaseIdx === 2) {
                          setCurrentUsecaseIdx(0);
                        } else {
                          setCurrentUsecaseIdx(currIdx => currIdx + 1);
                        }
                      }, 2000);
                    }}
                  >
                    {currentUsecase.updated}
                  </Typist>
                ) : (
                  currentUsecase.original
                )}
              </div>
              <div className={styles.PSA}>
                All generated content in this demo has been created by our AI.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.WritingReimagined}>
        <hr className={styles.SectionHr} />
        <div className={styles.ReimaginedHeader}>Superhuman Creativity</div>
        <p className={styles.ReimaginedText}>
          Recent advances in Artificial Intelligence have made it possible for
          AI algorithms to complete highly complex tasks, such as condensing
          information and expanding on ideas.
        </p>
        <p className={styles.ReimaginedText}>
          At GoCopy, we have harnessed this technology to help you get the job
          done faster. Our AI writing assistant will help you find ideas when
          you have none, rewrite content that doesn't feel quite right, write
          ads that will boost your click-through rates, and summarize text that
          has gotten too long. To name but a few.
        </p>
        <p className={styles.ReimaginedText}>
          By augmenting your writing workflow with our AI technology, we'll make
          you feel like you have superpowers.
        </p>
        <Robot className={styles.Robot} />
      </div>
      <div className={styles.Feature} ref={productRef}>
        <div className={styles.FeatureContainer}>
          <div className={styles.Left}>
            <div className={styles.FeatureTitle}>
              The complete writing productivity tool
            </div>
            <p className={styles.FeatureSubheader}>
              Write in our sleek and simple editor and turn to our tools when
              it’s time to harness the power of modern AI.
            </p>
            <p className={styles.FeatureSubheader}>
              Whether you are writing a blog post, an email, or a social media
              ad, leverage our state-of-the-art AI to help you turn your
              thoughts into fleshed out text.
            </p>
            <button
              className={styles.CTA}
              onClick={() => window.open(href.externalQuiz(), '_blank')}
            >
              Harness the power of AI now
              <Arrow className={styles.Arrow} />
            </button>
          </div>
          <div className={styles.Right}>
            <Editor className={styles.PlaygroundIcon} />
          </div>
        </div>
      </div>
      <div className={styles.ReverseFeature}>
        <div className={styles.ReverseFeatureContainer}>
          <div className={styles.ReverseLeft}>
            <Ideas className={styles.WritersBlockIcon} />
          </div>
          <div className={styles.ReverseRight}>
            <div className={styles.FeatureTitle}>
              Say goodbye to writer’s block
            </div>
            <p className={styles.FeatureSubheader}>
              <span className={styles.SubheaderIntro}>Feeling stuck?</span> Let
              our writing assistant generate ideas that will to inspire you.
            </p>
            <button
              className={styles.CTA}
              onClick={() => window.open(href.externalQuiz(), '_blank')}
            >
              Flush writer's block down the toilet
              <Arrow className={styles.Arrow} />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.Feature}>
        <div className={styles.FeatureContainer}>
          <div className={styles.Left}>
            <div className={styles.FeatureTitle}>Go fast</div>
            <p className={styles.FeatureSubheader}>
              <span className={styles.SubheaderIntro}>
                We have the need for speed. Do you?
              </span>{' '}
              The keyboard is faster than the mouse. That’s why we have included
              extensive keyboard shortcuts to help you get work done faster.
            </p>
            <Tippy content='Meep Meep!'>
              <button
                className={styles.CTA}
                onClick={() => window.open(href.externalQuiz(), '_blank')}
              >
                Go so fast, not even the Roadrunner can keep up
                <Arrow className={styles.Arrow} />
              </button>
            </Tippy>
          </div>
          <div className={classNames(styles.Right, styles.Shortcut)}>
            <div className={styles.Key}>
              <span className={styles.KeyTitle}>⌘</span>
            </div>
            <div className={styles.Key}>
              <span className={styles.KeyTitle}>K</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Final}>
        <div className={styles.UseCasesList}>
          <div className={styles.Title}>The all-in-one solution</div>
          <div className={styles.CardsContainer}>
            <div className={styles.Card}>
              <Writing className={styles.CardIcon} />
              <div className={styles.CardTitle}>Writing Tools</div>
              <div className={styles.CardItems}>
                <div className={styles.CardItem}>Listicle</div>
                <div className={styles.CardItem}>Expand</div>
                <div className={styles.CardItem}>Rewrite</div>
                <div className={styles.CardItem}>Condense</div>
              </div>
            </div>
            <div className={styles.Card}>
              <Blog className={styles.CardIcon} />
              <div className={styles.CardTitle}>Blogging</div>
              <div className={styles.CardItems}>
                <div className={styles.CardItem}>Titles</div>
                <div className={styles.CardItem}>Ideas</div>
                <div className={styles.CardItem}>Outlines</div>
                <div className={styles.CardItem}>Introductions</div>
              </div>
            </div>
            <div className={styles.Card}>
              <DigitalAds className={styles.CardIcon} />
              <div className={styles.CardTitle}>Digital Ads</div>
              <div className={styles.CardItems}>
                <div className={styles.CardItem}>Google</div>
                <div className={styles.CardItem}>LinkedIn</div>
                <div className={styles.CardItem}>Facebook</div>
                <div className={styles.CardItem}>Instagram</div>
              </div>
            </div>
            <div className={styles.Card}>
              <Ecommerce className={styles.CardIcon} />
              <div className={styles.CardTitle}>E-commerce</div>
              <div className={styles.CardItems}>
                <div className={styles.CardItem}>Microcopy</div>
                <div className={styles.CardItem}>Product Descriptions</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Testimonials} ref={testimonialRef}>
          <div className={styles.TestimonialTitle}>
            Loved by professional copywriters and entrepreneurs
          </div>
          <div className={styles.TestimonialCards}>
            <TestimonialCard
              testimonial="Haven't experienced writer's block since I started using GoCopy.
                Game changer!"
              avatarImg={SevdaImg}
              name='Sevda Anefi'
              job='Entrepreneur'
            />
            <TestimonialCard
              testimonial="It's like magic! GoCopy helps me get my work done so much
              faster. I highly recommend GoCopy to anybody who has to write a
              lot."
              avatarImg={AimeeImg}
              name='Aimee Bradshaw'
              job='Copywriter at startups.co.uk'
            />
            <TestimonialCard
              testimonial='I have been using GoCopy for about a month and it is has 10x the
                speed at which I write. Super super useful.'
              avatarImg={AnthonyImg}
              name='Anthony Young'
              job='Co-founder of Tyde'
            />
            <TestimonialCard
              testimonial='Not one to hand over cash easily. But this has been worth it.
                GoCopy feels like a little superpower!'
              avatarImg={HannahImg}
              name='Hannah Cesarani'
              job='Copywriter at MyArtBroker'
            />
            <TestimonialCard
              testimonial='We get a lot of leads through SEO. Because of GoCopy, we have
            been able to double the amount of content we produce. This has
            led to a significant increase in leads.'
              avatarImg={VitImg}
              name='Vit Jiracek'
              job='Founder of VS Digital Media'
            />
            <TestimonialCard
              testimonial='This is an amazing productivity tool! Whenever I get stuck I use
            GoCopy and its suggestions immediately get me unstuck.'
              avatarImg={GrahamImg}
              name='Graham Ensor'
              job='Senior Creative Copywriter at VCCP'
            />
          </div>
        </div>
        <div className={styles.Pricing} ref={pricingRef}>
          <div className={styles.PricingTitle}>Elevate your writing</div>
          <div className={styles.PricingSubheaderContainer}>
            <p className={styles.Subheader}>
              Use GoCopy for free for 14 days. Upgrade to enable unlimited access
              to our writing assistant and creativity tools.
            </p>
            <span>
              <span className={styles.Subheader}>For enterprise plans,</span>
              <span
                className={classNames(
                  styles.Subheader,
                  styles.SubheaderContact,
                )}
                onClick={() =>
                  (window.location.href =
                    'mailto:support@gocopy.io?subject=Enterprise Subscription Interest')
                }
              >
                contact us.
              </span>
            </span>
          </div>
          <Pricing
            activeSubscription={undefined}
            isSubscriptionMonthly={undefined}
            freeText='Try for free'
            proText='Try for free'
            starterText='Try for free'
            unlimitedText='Try for free'

            freeOnClick={() => window.open(href.externalQuiz(), '_blank')}
            proOnClick={() => window.open(href.externalQuiz(), '_blank')}
            starterOnClick={() => window.open(href.externalQuiz(), '_blank')}
            unlimitedOnClick={() => window.open(href.externalQuiz(), '_blank')}
          />
        </div>
        <div className={styles.CTASection}>
          <div className={styles.CTATitle}>Great Writing Awaits</div>
          <p className={styles.CTASubheader}>
            If you would like discover what you can accomplish with the power of
            GoCopy at your fingertips, get started today.
          </p>
          <button
            className={styles.CTA}
            onClick={() => window.open(href.externalQuiz(), '_blank')}
          >
            Start your free trial
            <Arrow className={styles.Arrow} />
          </button>
          <div className={styles.CheckmarkContainer}>
            <div className={styles.CheckMark}>
              <CheckIcon className={styles.CheckIcon} />
              {/* <div className={styles.CheckMarkDescription}>
                No credit card required for free account
              </div> */}
              <div className={styles.CheckMarkDescription}>
                Free account
              </div>
            </div>
            <div className={styles.CheckMark}>
              <CheckIcon className={styles.CheckIcon} />
              <div className={styles.CheckMarkDescription}>
                14 day free trial
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        pricingRef={pricingRef}
        productRef={productRef}
        testimonialRef={testimonialRef}
      />
    </div>
  );
}
