import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import FormInput from 'src/components/FormInput';
import Spinner from 'src/components/Spinner';
import href from 'src/modules/href';
import { getUser, getUsers } from 'src/redux/selectors/user';
import styles from './SettingsPage.module.scss';
import { useApi } from 'src/api';
import { validateEmail } from 'src/utils/validation';
import { colours } from 'src/modules/colours';
import DashboardWrapper from 'src/components/DashboardWrapper';
import { SettingsTabs, tabs } from 'src/modules/settings';
import { tabs as dashboardTabs } from 'src/modules/dashboard';
import {
  loadPublishableKey,
  starterV2PriceIds,
  proV2PriceIds,
  unlimitedV2PriceIds,
  startedPriceIds,
  unlimitedShakespearePriceIds,
} from 'src/utils/priceIds';
import Pricing from 'src/components/NewPricing';
import { loadStripe } from '@stripe/stripe-js';
import { getClientReferenceId } from './rewardful';
import {
  intervalTypes,
  subscriptionTypes,
  SubscriptionTypes,
} from 'src/modules/user';
import { getUserId } from 'src/redux/selectors/auth';
import AddTeammateModal from 'src/components/Modals/AddTeammateModal';
import { getSharedFoldersAdmin } from 'src/redux/selectors/shared';
import { getProjects } from 'src/redux/selectors/project';

function Billing(): JSX.Element {
  const api = useApi();
  const user = useSelector(getUser);
  const userId = useSelector(getUserId);
  const [isMonthly, setIsMonthly] = useState(false);
  const [code, setCode] = useState('');
  const [apiError, setApiError] = useState('');
  const [subscriptionType, setSubscriptionType] = useState<
    SubscriptionTypes | undefined
  >(undefined);
  useEffect(() => {
    if (user) {
      const {
        subscription: { type },
      } = user;
      setSubscriptionType(type);
      setIsMonthly(user.subscription.interval === intervalTypes.month);
    }
  }, [user]);
  if (!user) {
    return (
      <div className={styles.Billing}>
        <Spinner size={18} color={colours.black} />
      </div>
    );
  }
  const date = new Date(user.subscription.currentPeriodEnd);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(
    'en-US',
    options as Intl.DateTimeFormatOptions,
  );

  return (
    <div className={styles.Billing}>
      <div className={styles.PlanContainer}>
        <div className={styles.Subheader}>Current Plan</div>
        <div className={styles.Currentplan}>
          <div className={styles.Text}>You are currently on the</div>
          <div className={styles.Plan}>{user.subscription.type}</div>
          <div className={styles.Text}>plan.</div>
        </div>
        {(user.subscription.type === subscriptionTypes.appsumoSelectStarter ||
          user.subscription.type === subscriptionTypes.appsumoSelectPro) && (
          <div className={classNames(styles.Text, styles.AsFeatures)}>
            {user.subscription.type === subscriptionTypes.appsumoSelectStarter
              ? 'What is included: Unlimited short-form / 15 long-form runs'
              : 'What is included: Unlimited short-form / 65    long-form runs'}
          </div>
        )}
        {(user.subscription.type === subscriptionTypes.pro ||
          user.subscription.type === subscriptionTypes.starter ||
          user.subscription.type === subscriptionTypes.starterV2 ||
          user.subscription.type === subscriptionTypes.proV2 ||
          user.subscription.type === subscriptionTypes.unlimitedV2 ||
          user.subscription.type === subscriptionTypes.appsumoUnlimitedMonthly ||
          user.subscription.type === subscriptionTypes.appsumoUnlimitedAnnual) && (
          <div>
            <div className={styles.ExpiryContainer}>
              <div className={styles.Text}>You're next payment is on</div>
              <div className={styles.Date}>{formattedDate}</div>
            </div>
            <button
              className={styles.Manage}
              onClick={() => {
                api
                  .createCustomerPortal(user.subscription.customerId)
                  .then(res => {
                    window.location.href = res.url;
                  });
              }}
            >
              Manage your subscription here
            </button>
            <div>
              <button className={styles.Button} onClick={() => window.open(href.stripeBilling(), '_blank')}>
              Update Billing Info
              </button>

            </div>
            
          </div>
        )}
      </div>
      {subscriptionType === subscriptionTypes.appsumoSelectStarter && (
        <>
          <hr className={styles.HR} />
          <div className={styles.Subheader}>Stack AppSumo Codes</div>
          {apiError && <div className={styles.CodeError}>{apiError}</div>}
          <div className={styles.AppsumoCode}>
            <FormInput
              placeholderText='Enter your code'
              password={false}
              input={code}
              onChange={e => setCode(e.target.value)}
              className={styles.FormInput}
            />
            <button
              className={styles.ASButton}
              onClick={() =>
                userId &&
                api
                  .stackCode({ code, userId })
                  .then(() => {
                    setCode('');
                    setApiError('');
                  })
                  .catch(() => setApiError('Code is invalid'))
              }
            >
              Submit Code
            </button>
          </div>
        </>
      )}
      <hr className={styles.HR} />
      <div className={styles.Subheader}>Plans</div>
      <Pricing
        key={`${isMonthly}`}
        setIsBillingMonthly={setIsMonthly}
        isSubscriptionMonthly={isMonthly}
        activeSubscription={subscriptionType}
        freeOnClick={() => {}}
        starterOnClick={() => {
          if (user.subscription.type !== subscriptionTypes.starterV2) {
            api
              .createCheckoutSession({
                priceId: isMonthly
                  ? starterV2PriceIds.monthly
                  : starterV2PriceIds.annual,
                customerId: user.subscription.customerId,
                clientReferenceId: getClientReferenceId(),
              })
              .then(async data => {
                const stripe = await loadStripe(loadPublishableKey);
                stripe?.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });

          }
        }}
        proOnClick={() => {
          if (user.subscription.type !== subscriptionTypes.proV2) {
            api
              .createCheckoutSession({
                priceId: isMonthly ? proV2PriceIds.monthly : proV2PriceIds.annual,
                customerId: user.subscription.customerId,
                clientReferenceId: getClientReferenceId(),
                // discount: !isMonthly
                //   ? {
                //       promotion_code: 'promo_1J4TCIBtw7j6HkoutYH3FKvD',
                //     }
                //   : undefined,
              })
              .then(async data => {
                const stripe = await loadStripe(loadPublishableKey);
                stripe?.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              
          }
        }}

        unlimitedOnClick={() => {
          if (user.subscription.type !== subscriptionTypes.unlimitedV2) {
            api
              .createCheckoutSession({
                priceId: isMonthly ? unlimitedV2PriceIds.monthly : unlimitedV2PriceIds.annual,
                customerId: user.subscription.customerId,
                clientReferenceId: getClientReferenceId(),
                // discount: !isMonthly
                //   ? {
                //       promotion_code: 'promo_1J4TCIBtw7j6HkoutYH3FKvD',
                //     }
                //   : undefined,
              })
              .then(async data => {
                const stripe = await loadStripe(loadPublishableKey);
                stripe?.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
          }
        }}

        freeText='free trial over'
        starterText='Upgrade'
        proText='Upgrade'
        unlimitedText='Upgrade'
      />
      {(user.subscription.type === subscriptionTypes.appsumo ||
        user.subscription.type === subscriptionTypes.appsumoUnlimitedAnnual ||
        user.subscription.type === subscriptionTypes.appsumoUnlimitedMonthly ||
        user.subscription.type === subscriptionTypes.appsumoSelectStarter ||
        user.subscription.type === subscriptionTypes.appsumoSelectPro) && (
        <>
          <hr className={styles.HR} />
          <div className={styles.Subheader}>
            Special LTD Unlimited Long-Form Deal
          </div>
          {/* <div className={styles.Deal}>
            Get over 20% off by purchasing the Annual plan. This deal ends on
            the 2nd of June 2021.
          </div> */}
          <div className={styles.Prices}>
            <div className={styles.Card}>
              <h4>Monthly</h4>
              <div>$19 per month</div>
              <button
                className={styles.Button}
                onClick={() => {
                  api
                    .createCheckoutSession({
                      priceId: unlimitedShakespearePriceIds.monthly,
                      customerId: user.subscription.customerId,
                    })
                    .then(async data => {
                      const stripe = await loadStripe(loadPublishableKey);
                      stripe?.redirectToCheckout({
                        sessionId: data.sessionId,
                      });
                    });
                }}
              >
                {subscriptionTypes.appsumoUnlimitedMonthly === subscriptionType
                  ? 'Manage'
                  : 'Upgrade'}
              </button>
            </div>
            {/* <div className={styles.Card}>
              <h3>Annual</h3>
              <div className={styles.Deal}>$15 per month</div>
              <button
                className={styles.Button}
                onClick={() => {
                  api
                    .createCheckoutSession({
                      priceId: unlimitedShakespearePriceIds.annual,
                      customerId: user.subscription.customerId,
                    })
                    .then(async data => {
                      const stripe = await loadStripe(loadPublishableKey);
                      stripe?.redirectToCheckout({
                        sessionId: data.sessionId,
                      });
                    });
                }}
              >
                {subscriptionTypes.appsumoUnlimitedAnnual === subscriptionType
                  ? 'Manage'
                  : 'Upgrade'}
              </button>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
}

function General(): JSX.Element {
  const history = useHistory();
  const api = useApi();
  const user = useSelector(getUser);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [email, setEmail] = useState('');
  const [apiEmail, setApiEmail] = useState('');
  const emailError = validateEmail(email);
  const isDisabled = email === apiEmail;

  useEffect(() => {
    if (user) {
      const { email: oldEmail } = user;
      setEmail(oldEmail);
      setApiEmail(oldEmail);
    }
  }, [user]);

  if (!user) {
    return (
      <div className={styles.Form}>
        <Spinner size={16} color={colours.black} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.Form}>
        <div className={styles.TitleContainer}>
          <span className={styles.FormTitle}>Email</span>
        </div>
        <FormInput
          input={email}
          onChange={e => setEmail(e.target.value)}
          placeholderText='E-mail'
          password={false}
          className={styles.FormInput}
          error={buttonClicked ? emailError : ''}
        />
      </div>
      <div className={styles.Form}>
        <div className={styles.TitleContainer}>
          <span className={styles.FormTitle}>Password</span>
        </div>
        <div
          className={styles.Reset}
          onClick={() => history.push(href.resetPassword())}
        >
          Reset Password
        </div>
      </div>
      <button
        disabled={isDisabled}
        className={classNames(
          styles.GeneralButton,
          isDisabled && styles.Disabled,
        )}
        onClick={() => {
          setButtonClicked(true);
          if (!emailError) {
            setIsLoading(true);
            api
              .updateUser({ ...user, email })
              .then(() => setIsLoading(false))
              .catch(() => {
                setIsLoading(false);
              });
          }
        }}
      >
        {isLoading ? <Spinner size={12} color='#ffffff' /> : 'Update'}
      </button>
    </>
  );
}

type TeamProps = {
  setShowTeammate: (a: boolean) => void;
};

function Team({ setShowTeammate }: TeamProps): JSX.Element {
  const api = useApi();
  const sharedFolders = useSelector(getSharedFoldersAdmin);
  const userId = useSelector(getUserId);
  const projects = useSelector(getProjects);
  const users = useSelector(getUsers);
  return (
    <div>
      <div className={styles.TeamHeader}>
        <div className={styles.ManageTeam}>Manage your team</div>
        <button
          className={styles.AddTeam}
          onClick={() => setShowTeammate(true)}
        >
          Add Teammate
        </button>
      </div>
      <div className={styles.TeamList}>
        {sharedFolders &&
          sharedFolders
            .filter(f => f.members.length > 1)
            .map((f, i) => (
              <div className={styles.FolderContainer} key={i}>
                <div className={styles.FolderTitle}>
                  {projects?.find(p => p.projectId === f.folderId)?.name}
                </div>
                <div className={styles.Members}>
                  {f.members
                    .filter(m => m !== userId)
                    .map((m, j) => (
                      <div className={styles.Member} key={j}>
                        <div className={styles.Email}>
                          {users && users.find(u => u.userId === m)?.email}
                        </div>
                        <div
                          className={styles.Remove}
                          onClick={() => {
                            if (userId) {
                              api.removeMemberFromSharedFolder({
                                adminId: userId,
                                folderId: f.folderId,
                                memberId: m,
                              });
                            }
                          }}
                        >
                          Remove user
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default function SettingsPage(): JSX.Element {
  const [showTeammate, setShowTeammate] = useState(false);
  const [settingsTab, setSettingsTab] = useState<SettingsTabs>(tabs.general);
  const api = useApi();
  const userId = useSelector(getUserId);
  useEffect(() => {
    if (userId) {
      api.getUser(userId);
      api.getSharedFoldersAdmin(userId);
      api.getProjects(userId);
      api.getUsers();
    }
  }, [api, userId]);
  return (
    <DashboardWrapper
      tab={dashboardTabs.settings}
      helmetUrl={href.settings()}
      isOverlayActive={showTeammate}
      modalInJsx={<AddTeammateModal setShowModal={setShowTeammate} />}
    >
      <>
        <div className={styles.Header}>
          <div className={styles.Title}>
            <span>Settings</span>
          </div>
          <div className={styles.SettingsTabs}>
            <div
              className={classNames(
                styles.SettingsTab,
                settingsTab === tabs.general && styles.SelectedTab,
              )}
              onClick={() => setSettingsTab(tabs.general)}
            >
              {tabs.general}
            </div>
            <div
              className={classNames(
                styles.SettingsTab,
                settingsTab === tabs.billing && styles.SelectedTab,
              )}
              onClick={() => setSettingsTab(tabs.billing)}
            >
              {tabs.billing}
            </div>
            <div
              className={classNames(
                styles.SettingsTab,
                settingsTab === tabs.team && styles.SelectedTab,
              )}
              onClick={() => setSettingsTab(tabs.team)}
            >
              {tabs.team}
            </div>
          </div>
        </div>
        {
          {
            [tabs.general]: <General />,
            [tabs.billing]: <Billing />,
            [tabs.team]: <Team setShowTeammate={setShowTeammate} />,
          }[settingsTab]
        }
      </>
    </DashboardWrapper>
  );
}
