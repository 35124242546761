import { Values } from '../types';

export const subscriptionTypes = {
  proV2: 'GoCopy Pro',
  starterV2: 'GoCopy Starter',
  unlimitedV2: 'GoCopy Unlimited',


  free: 'Free',
  starter: 'Starter',
  pro: 'Pro',
  enterprise: 'Enterprise',
  appsumo: 'Appsumo',
  appsumoUnlimitedMonthly: 'LTD Unlimited Shakespeare AI Monthly',
  appsumoUnlimitedAnnual: 'LTD Unlimited Shakespeare AI Annual',
  appsumoSelectStarter: 'AppSumo Select Starter',
  appsumoSelectPro: 'AppSumo Select Pro',
} as const;
export type SubscriptionTypes = Values<typeof subscriptionTypes>;

export const subscriptionStatus = {
  active: 'Active',
  cancelled: 'Cancelled',
} as const;
export type SubscriptionStatus = Values<typeof subscriptionStatus>;

export const intervalTypes = {
  trial: 'trial',
  month: 'month',
  year: 'year',
} as const;
export type IntervalTypes = Values<typeof intervalTypes>;

export interface User {
  userId: string;
  email: string;
  password: string;
  showTutorial?: boolean;
  subscription: {
    // Stripe customer id
    customerId: string;
    type: SubscriptionTypes;
    // date formatted as string;
    currentPeriodEnd: string;
    status: SubscriptionStatus;
    interval: IntervalTypes;
  };
}

export interface UserForm {
  email: string;
  password: string;
}
