import React from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/api';
import Icon from 'src/assets/android-chrome-512x512.png';
import { getUser } from 'src/redux/selectors/user';
import styles from './WelcomeModal.module.scss';

type Props = {
  setStartTour: (a: boolean) => void;
  setShowModal: (a: boolean) => void;
};

export default function WelcomeModal({
  setStartTour,
  setShowModal,
}: Props): JSX.Element {
  const api = useApi();
  const user = useSelector(getUser);
  return (
    <div className={styles.WelcomeModal}>
      <div className={styles.IconContainer}>
        <img src={Icon} alt='icon' className={styles.Icon} />
      </div>
      <div className={styles.TitleContainer}>
        <span>Welcome to GoCopy!</span>
      </div>
      <div className={styles.SubtitleContainer}>
        <span>Ready to write something great?</span>
      </div>
      <div className={styles.ButtonsContainer}>
        <button
          className={styles.TourButton}
          onClick={() => {
            setStartTour(true);
            setShowModal(false);
          }}
        >
          Take a quick tour: Become a GoCopy Pro
        </button>
        <button
          className={styles.SkipButton}
          onClick={() => {
            if (user) {
              api
                .updateUser({ ...user, showTutorial: false })
                .then(() => setShowModal(false));
            }
          }}
        >
          Skip the tour
        </button>
      </div>
    </div>
  );
}
