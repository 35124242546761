import { pickValueForDeploymentEnv } from 'src/utils/env';

export const proV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1LPDMNLrsD2b6N4v299mZdcV',
    annual: 'price_1LPDMNLrsD2b6N4v4ME6V26G',
  },
  {
    monthly: 'price_1LFN5xLrsD2b6N4vcucycMC0',
    annual: 'price_1LFN5xLrsD2b6N4vB8XbM1a7',
  },
);

export const starterV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1LOBJPLrsD2b6N4vzCUCjMql',
    annual: 'price_1LOsZkLrsD2b6N4v5JqnkrTy',
  },
  {
    monthly: 'price_1LWVTkLrsD2b6N4vecQGAzuL',
    annual: 'price_1LFN5GLrsD2b6N4vfq9Cn2E5',
  },
);


export const unlimitedV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1Ll2kdLrsD2b6N4vUAH3LiE1',
    annual: 'price_1Ll2kdLrsD2b6N4vZQng3L0r',
  },
  {
    monthly: 'price_1LFN6bLrsD2b6N4vRgmf61uh',
    annual: 'price_1LFN6bLrsD2b6N4vj1tFBGS9',
  },
);



export const proPriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1IK90CBtw7j6HkouwJUX8YyR',
    annual: 'price_1IK90CBtw7j6HkoumYtY9AIU',
  },
  {
    monthly: 'price_1IegbYBtw7j6Hkou53YSLRqV',
    annual: 'price_1IpGoSBtw7j6Hkouhbe4PMbw',
  },
);

export const startedPriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1INLhzBtw7j6HkoutgZTz6rq',
    annual: 'price_1INLhzBtw7j6HkouSAcrIkjU',
  },
  {
    monthly: 'price_1IegaHBtw7j6Hkoue1cYqi8g',
    annual: 'price_1INLZgBtw7j6HkouteZnxqFL',
  },
);

export const unlimitedShakespearePriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1INLhzBtw7j6HkoutgZTz6rq',
    annual: 'price_1INLhzBtw7j6HkouSAcrIkjU',
  },
  {
    monthly: 'price_1Iv2gOBtw7j6Hkou80qWCLqC',
    annual: 'price_1IvIMoBtw7j6HkousE2pbkvh',
  },
);

export const loadPublishableKey = pickValueForDeploymentEnv(
  'pk_test_51LDdTDLrsD2b6N4v9cksKLpQMpcIFSXE8xy4WtrULsOvOHm6A3ygFdccAnvv0izlJezC1nmf1YfFYp4cQIWDtBUN00cbdjB9AK',
  'pk_live_51LDdTDLrsD2b6N4vZm0Jp4uukBQyLQVmitaULeR6HLjnsAmAUzPG8ejmEq8x3eKtsLQ1QcfcbenWoK1CI3jKmae500x2m3i2CH',
);

const liveLtdIds = {
  75: 'price_1Ie0JkBtw7j6HkouYlkp60Zr',
  100: 'price_1IdzDCBtw7j6HkouOF1Y2qd5',
  118: 'price_1JMYXhBtw7j6HkouNc0uwSsD',
  125: 'price_1IdzDiBtw7j6HkouPffhh2Ol',
  150: 'price_1IdzDrBtw7j6HkouiYKpSj4U',
  175: 'price_1IdzE1Btw7j6HkouliB58LsF',
  200: 'price_1IdzE9Btw7j6HkouScLkuQOZ',
};

const localLtdIds = {
  75: 'price_1IdjepBtw7j6HkouAuSwFnaG',
  100: 'price_1IdzDCBtw7j6HkouOF1Y2qd5',
  118: 'price_1JMYXhBtw7j6HkouNc0uwSsD',
  125: 'price_1IdzDiBtw7j6HkouPffhh2Ol',
  150: 'price_1IdzDrBtw7j6HkouiYKpSj4U',
  175: 'price_1IdzE1Btw7j6HkouliB58LsF',
  200: 'price_1IdzE9Btw7j6HkouScLkuQOZ',
};

export const ltdPriceIds = pickValueForDeploymentEnv(localLtdIds, liveLtdIds);
